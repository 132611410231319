<template>
  <!-- <div class="anno-root" :style="cssVars">
    <div style="height: 35px"></div>
    <div class="anno-content">
      <div class="anno-list">
        <div v-for="(item,index) in dataList" :key="index">
          <div class="anno-list-item">
            <div class="anno-list-item-content">
              <div v-if="showChinese != 'english'" class="titleStyle">{{ item.title }}</div>
              <div class="contentStyle">{{ item.content }}</div>

            </div>
            
            <div class="anno-list-item-date">{{ formatCreateTime(item.create_time) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="anno-root1">
    
    <el-row>
      <el-col :span="8">
        <div class="contentDiv">
          
          <div class="contentTitleDiv">Device Configuration Instruction</div>
          
          
          <div class="contentInfoDiv">
            To ensure the smooth running of the exam, please use:<br>
            <span style="color: #00348b;font-weight: 500;">・ A computer with a camera and microphone (tablets are not allowed)</span> <br>
            <span style="color: #00348b;font-weight: 500;">・ The latest version of the Chrome browser </span> <br>
            And configure your computer and browser according to the exam <span style="color: red;text-decoration: underline;font-weight: 500;cursor: pointer;" @click="gotoDetail()" >Equipment Guide</span><br><br>

            Failure to properly configure the equipment may result in the inability to enter the exam, incomplete use of the exam system functions, or exam interruptions, leading to exam failure.
          </div>

          


        </div>
      </el-col>
      <el-col :span="8">
        <div class="contentDiv">
          <div class="contentTitleDiv">Identity Verification Instruction</div>

          <div class="contentInfoDiv">
            To verify the identity of candidates, all participants must prepare an identity document in advance and have it photographed for inspection upon entering the exam room (the document photo is used solely for proctoring checks and will be deleted from the server within 5 working days after the exam scoring is completed).<br><br>
            An identity document refers to any official proof document that contains the individual's photo and name information (including but not limited to an identity card, passport, driver's license, school student ID, social security card, or other proof documents).
            
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="contentDiv">
          <div class="contentTitleDiv">Exam Disciplinary Instruction</div>

          <div class="contentInfoDiv">
            To ensure the fairness and integrity of the examination, the system will automatically monitor through the camera and screen-sharing features, and proctors will supervise in real-time from the backend.<br><br>
            Candidates should complete the exam independently in a quiet room, ensuring that their image is visible within the camera's range throughout the process. Violations of exam discipline may result in varying degrees of penalties, as detailed in the <span style="color: red;text-decoration: underline;font-weight: 500;cursor: pointer;" @click="gotoDetail1()" >Exam Disciplinary Policy and Deduction Guidelines</span>.
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import {getHomeAnnouncements} from '../../api/index'
import {formatDate} from '../../utils/date'
import '../../assets/common/font.css'

import { getProjectLanguage ,getProjectColor ,getProjectBU} from "../../utils/store";

export default {
  name: "index",
  data() {
    return {
      dataList: [],
      showChinese:getProjectLanguage(),
      mainColor:getProjectColor(), 

    }
  },
  computed: {
    cssVars() {
      return {
        "--color": this.mainColor
      };
    }
  },
  mounted() {
    // this.fetchData()

    console.log(this.showChinese)
  },
  methods: {
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    gotoDetail(){
      window.open('https://ap.learningfirst.tech/Equipment%20Setting%20Guide%20English.pdf','_blank')
    },
    gotoDetail1(){

      if (getProjectBU() == 'WEC'){
        window.open('https://ap.learningfirst.tech/WEC%20Deduction%20Policy.pdf','_blank')
      }else{
        window.open('https://ap.learningfirst.tech/IPsyO/IPsyO%20Exam%20Disciplinary%20Policy%20and%20Deduction%20Guidelines.pdf','_blank')
      }


    },
    fetchData() {
      getHomeAnnouncements().then((res) => {
        var result = res.data.data
        for (var i = 0; i < result.length; i++) {
          this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
          // this.dataList.push(result[i])
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.anno-list-item-content {
  flex: 1;
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: var(--color);
  font-size: 26px;
  // font-weight: bold;
}
.titleStyle{
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.contentStyle{
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
  font-size: 16px;

}
.anno-list-item-date {
  margin-right: 10px;
  font-family: Montserrat-SemiBold;
  color: var(--color);
  font-size: 20px;
  font-weight: bold;
}

.anno-list-item {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--color);
  display: flex;
  flex-direction: row;
}

.anno-root {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anno-content {
  flex: 1;
  width: 100%;
  background-color: #F3F5F9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.anno-list {
  padding-left: 40px;
  padding-right: 40px;
}

//new
.anno-root1 {
  margin: 0 auto;
  // width: 1100px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 5vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  // background-color: #00348b;
}
.contentDiv{
  margin-left: 5%;
  width: 90%;
  height: 55vh;
  border: 1px solid #00348b;
}

.contentTitleDiv{
  width: 100%;
  height: 30px;
  color: #333;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
}
.contentInfoDiv{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  line-height: 25px;
  word-break: normal;
  word-spacing: normal;
  color: #606266;
  font-size: 13px;
}
.tipClass{
  // background-color: rebeccapurple;
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
}
</style>